import { Store } from '@ngxs/store';
import { combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, share, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import {
  AppConfigModel,
  AppConfigState,
  AUTH_STATUS_CODES,
  AuthService,
  ConfigLoaderService,
  RuntimeState,
  SetAppConfigLoading,
  SetConfig,
  SpinnerEvent,
  SpinnerService
} from 'vnext-shared';
import _isEqual from 'lodash.isequal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LiveEditFacade {
  setLiveEditConfig$ = combineLatest([
    this.store.select(AppConfigState.loaded),
    this.store.select(AppConfigState.loading),
    this.authService.observeForJWT(),
    this.store.select(RuntimeState.authStatus)
  ]).pipe(
    skipWhile(([, , token, jwtStatus]) => !token && ![AUTH_STATUS_CODES.JWT_LOADED, AUTH_STATUS_CODES.JWT_SAVED].includes(jwtStatus)),
    filter(([loaded, loading]) => {
      return !this.isLoaded(loaded) && !loading;
    }),
    distinctUntilChanged(_isEqual),
    tap(() => {
      this.spinnerService.show(SpinnerEvent.APP_CONFIG_LOADING);
    }),
    switchMap(() => this.store.dispatch(new SetAppConfigLoading(true))),
    switchMap(() => this.configLoaderService.loadDynamicConfig$()),
    catchError(e => of(false)),
    filter(response => response !== false),
    switchMap(configuration => this.store.dispatch(new SetConfig(configuration as AppConfigModel))),
    share(),
    take(1)
  );

  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly configLoaderService: ConfigLoaderService
  ) {}

  /**
   * Predicate to determine if the incoming response is a JWT
   */
  private readonly isJWT = (jwt): boolean => typeof jwt === 'string';

  /**
   * Predicate to determine if the incoming response is loaded.
   */
  private isLoaded(loaded): boolean {
    return typeof loaded === 'boolean' && loaded === true && this.authService.getToken() !== null;
  }
}
