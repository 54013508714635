import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DocumentInterruptSource, Idle } from '@ng-idle/core';
import { Store } from '@ngxs/store';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import {
  AppConfigState,
  AuthService,
  ConfigResourceService,
  ConfirmationModalComponent,
  DrugsService,
  IdleInterruptContext,
  LoginService,
  PageTags,
  SiteType,
  NavigationUtilityService
} from 'vnext-shared';

const SESSION_IDLE_MINUTE_RESOURCE = 'Spa_Site_Session_Idle_Minutes';
const SESSION_IDLE_MINUTE_DEFAULT = 15;
const SESSION_TIMEOUT_MINUTE_RESOURCE = 'Spa_Site_Session_Timeout_Minutes';
const SESSION_TIMEOUT_MINUTE_DEFAULT = 5;
const SHORT_IDLE_AFTER_TIMEOUT_EXPIRED_ON_BLUR = 1;
const SHORT_TIMEOUT_AFTER_TIMEOUT_EXPIRED_ON_BLUR = 10;
/**
 * Contains session idle modal functionality including base configuration
 */
@Injectable({ providedIn: 'root' })
export class SessionIdleService implements OnDestroy {
  idleModal: NgbModalRef;
  private timeoutExpiredOnBlur: boolean = false;
  private readonly idleSeconds: number =
    this.configResourceService.getProcessedResourceValue(SESSION_IDLE_MINUTE_RESOURCE, null, SESSION_IDLE_MINUTE_DEFAULT) * 60;

  private readonly timeoutSeconds: number =
    this.configResourceService.getProcessedResourceValue(SESSION_TIMEOUT_MINUTE_RESOURCE, null, SESSION_TIMEOUT_MINUTE_DEFAULT) * 60;

  private readonly isTabFocused$: Observable<boolean> = merge(
    fromEvent(window, 'blur').pipe(map(() => false)),
    fromEvent(window, 'focus').pipe(map(() => true))
  ).pipe(distinctUntilChanged(), shareReplay(1));

  private readonly subscriptions: Subscription = new Subscription();

  // making this private so people don't use SessionIdleService to grab siteType
  private siteType: string;

  constructor(
    private readonly modalService: NgbModal,
    private readonly configResourceService: ConfigResourceService,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly route: Router,
    private readonly idle: Idle,
    private readonly drugsService: DrugsService,
    private readonly ngZone: NgZone,
    private readonly idleInterruptContext: IdleInterruptContext,
    private navigationUtilityService: NavigationUtilityService
  ) {
    // providing a hook for manual interrupts of the idle
    this.subscriptions.add(
      this.idleInterruptContext.interruptIdle$().subscribe({
        next: () => {
          if (this.idle.isRunning()) {
            this.idle.interrupt();
          }
        }
      })
    );

    this.subscriptions.add(
      this.isTabFocused$.subscribe({
        next: isTabFocused => {
          if (isTabFocused && this.timeoutExpiredOnBlur) {
            this.setIdleAndTimeout(SHORT_IDLE_AFTER_TIMEOUT_EXPIRED_ON_BLUR, SHORT_TIMEOUT_AFTER_TIMEOUT_EXPIRED_ON_BLUR);
            this.idle.watch();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeIdle(): void {
    this.siteType = this.configResourceService.getProcessedResourceValue('Spa_Site_SiteType', null, SiteType.CONSUMER);
    this.configureIdle();
    this.idle.watch();
  }

  configureIdle(): void {
    const isDebugMode: boolean = this.configResourceService.getProcessedResourceValue(
      'Spa_Site_Session_IdleTimeout_EnableDebugMode',
      null,
      false
    );
    this.setIdleAndTimeout(this.idleSeconds, this.timeoutSeconds);
    this.idle.setInterrupts([
      new DocumentInterruptSource('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')
    ]);

    this.idle.onTimeout.pipe(withLatestFrom(this.isTabFocused$)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async ([_timeout, isTabFocused]) => {
        // If API session expired, refresh the JWT in order to save drug list and create a member session
        // It also covers #SPA-3923
        if (!this.authService.isAuthenticatedForApiCalls()) {
          await this.authService.loadJWT();
        }
        if (isTabFocused) {
          // Debuggin SPA-6905, to be removed
          if (isDebugMode) {
            console.log('[NgIdle] onTimeout', new Date());
          }

          this.drugsService.saveDrugList();
          // running through the logout regardless of logged in state as it is the most
          // comprehensive way to clear the store right now and redirect to correct page
          if (this.isCurrentRouteNotAgentLogin()) {
            // On Chrome, after 5 minutes of inactivity (another browser's tab selected or another window active)
            // causes the navigation to be triggered outside Angular zone
            this.ngZone.run(() => {
              this.loginService.logout(this.siteType);
            });
          }
          if (this.idleModal) {
            this.idleModal.close();
          }
          this.modalService.dismissAll();
          this.timeoutExpiredOnBlur = false;
          this.setIdleAndTimeout(this.idleSeconds, this.timeoutSeconds);
          this.idle.watch();
        } else {
          this.timeoutExpiredOnBlur = true;
        }
      }
    });
    this.idle.onIdleStart.subscribe({
      next: () => {
        // Debuggin SPA-6905, to be removed
        if (isDebugMode) {
          console.log('[NgIdle] onIdleStart', new Date());
        }
        // Hide session expiry popup on the Agent login page.
        if (this.isCurrentRouteNotAgentLogin() && this.isCurrentRouteNotAgentSubscription() && !this.modalService.hasOpenModals()) {
          this.openSessionIdleModal();
        }
      }
    });
    if (isDebugMode) {
      // Debuggin SPA-6905, to be removed
      this.idle.onInterrupt.subscribe({
        next: eventArgs => {
          console.log('[NgIdle] onInterrupt', { eventArgs, timestamp: new Date() });
        }
      });
      this.idle.onTimeoutWarning.subscribe({
        next: countdown => {
          console.log(`[NgIdle] onTimeoutWarning - ${countdown} seconds`);
        }
      });
      this.idle.onIdleEnd.subscribe({
        next: () => {
          console.log('[NgIdle] onIdleEnd', new Date());
        }
      });
    }
  }

  openSessionIdleModal(): void {
    if (this.idleModal) {
      this.idleModal.close();
    }
    const options: NgbModalOptions = {
      ariaLabelledBy: 'modal_header_title',
      centered: true
    };
    this.idleModal = this.modalService.open(ConfirmationModalComponent, options);
    this.idleModal.componentInstance.title = this.configResourceService.getProcessedResourceValue(
      'Spa_Site_Idle_Modal_Title',
      null,
      'Session timeout'
    );
    this.idleModal.componentInstance.prompt = this.configResourceService.getProcessedResourceValue(
      'Spa_Site_Idle_Modal_Prompt',
      null,
      'In order to protect your privacy, we will end your session automatically due to inactivity. Otherwise, you may choose to ' +
        'continue your session.'
    );
    this.idleModal.componentInstance.cancelButton = this.configResourceService.getProcessedResourceValue(
      'Spa_Site_Idle_End_Text',
      null,
      'End now'
    );
    this.idleModal.componentInstance.confirmButton = this.configResourceService.getProcessedResourceValue(
      'Spa_Site_Idle_Continue_Text',
      null,
      'Continue session'
    );
    this.idleModal.componentInstance.cancelAction = async () => {
      this.timeoutExpiredOnBlur = false;
      this.setIdleAndTimeout(this.idleSeconds, this.timeoutSeconds);
      // If API session expired, refresh the JWT in order to save drug list and create a member session
      if (!this.authService.isAuthenticatedForApiCalls()) {
        await this.authService.loadJWT();
      }
      this.drugsService.saveDrugList();
      this.loginService.logout(this.siteType);
    };
    this.idleModal.componentInstance.confirmAction = () => {
      this.timeoutExpiredOnBlur = false;
      this.setIdleAndTimeout(this.idleSeconds, this.timeoutSeconds);
      this.idle.watch();
    };
  }

  private isCurrentRouteNotAgentSubscription(): boolean {
    const currentRouteUrl = this.route.url;
    const agentSubscriptionPageUrl: string = this.navigationUtilityService.constructHref(PageTags.AGENT_SUBSCRIPTION);
    const agentSubscriptionConfirmationPageUrl: string = this.navigationUtilityService.constructHref(PageTags.SUBSCRIPTION_CONFIRMATION);
    const isNotAgentSubscriptionRoute =
      !currentRouteUrl.includes(agentSubscriptionPageUrl) && !currentRouteUrl.includes(agentSubscriptionConfirmationPageUrl);
    return isNotAgentSubscriptionRoute;
  }

  private isCurrentRouteNotAgentLogin(): boolean {
    const currentRouteUrl = this.route.url;
    const pageUrl: string = this.navigationUtilityService.constructHref(PageTags.AGENT_LOGIN);
    const newLocal = !currentRouteUrl.includes(pageUrl);
    return newLocal;
  }

  private setIdleAndTimeout(idleSeconds: number, timeoutSeconds: number): void {
    this.idle.setIdle(idleSeconds);
    this.idle.setTimeout(timeoutSeconds);
  }
}
