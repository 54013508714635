import { Injectable } from '@angular/core';
import { StorageEngine } from '@ngxs/storage-plugin';
import _get from 'lodash.get';
import * as LZString_ from 'lz-string';
import _isEqual from 'lodash.isequal';
import { environment } from 'src/environments/environment';
import { APP_CONFIG_DEFAULTS, PlanTypeMetadataMap, PlansStateModel } from 'vnext-shared';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../assets/config.json';

interface StoredSession {
  sessionStorageItem: any;
  sessionStorageObject: any;
}

const LZString = LZString_;

@Injectable()
export class VNextStorageEngine implements StorageEngine {
  constructor() {
    this.initializeCompressionSettings();
    if (this.compressPlanData) {
      this.setItem$
        .asObservable()
        .pipe(debounceTime(this.compressPlanDataDebounceTime))
        .subscribe(setItem => {
          this.performSetItem(setItem.key, setItem.val);
        });
    }
  }

  private compressPlanData = false;
  private compressPlanDataDebounceTime = 200;
  private readonly setItem$ = new BehaviorSubject<any>(null);
  appConfig;
  planTypeDataMap: PlanTypeMetadataMap = {};
  get length(): number {
    // length represents the number of top level storage elements (currently application, ui, liveEdit)
    return sessionStorage.length;
  }

  getItem(key: string): any {
    if (this.compressPlanData) {
      return this.getCompressedItem(key);
    } else {
      const sessionStorageItem = sessionStorage.getItem(key);
      if (sessionStorageItem && key === 'application') {
        if (!environment.liveedit) {
          return JSON.stringify({ ...JSON.parse(sessionStorageItem), appConfig: this.getAppConfig() });
        } else {
          const sessionStorage = JSON.parse(sessionStorageItem);
          const inMemoryAppConfig = this.getAppConfig();
          return JSON.stringify({
            ...sessionStorage,
            appConfig: {
              ...sessionStorage.appConfig,
              resourceBundle: inMemoryAppConfig.resourceBundle,
              resourceMetadata: inMemoryAppConfig.resourceMetadata
            }
          });
        }
      }
      return sessionStorageItem;
    }
  }

  getCompressedItem(key: string): any {
    const sessionStorageItem = sessionStorage.getItem(key);
    const sessionStorageObject = JSON.parse(sessionStorageItem);
    if (!environment.liveedit && sessionStorageItem && key === 'application') {
      const planTypeDataMap = sessionStorageObject.plans?.planTypeDataMap;
      const planTypeDataMapKeys = Object.keys(planTypeDataMap);
      const planTypeDataMapObject = planTypeDataMapKeys?.reduce((map, planTypeDataMapKey) => {
        map[planTypeDataMapKey] = JSON.parse(LZString.decompressFromUTF16(planTypeDataMap[planTypeDataMapKey]));
        return map;
      }, {});
      return JSON.stringify({
        ...sessionStorageObject,
        appConfig: this.getAppConfig(),
        plans: {
          ...sessionStorageObject.plans,
          planTypeDataMap: planTypeDataMapObject ?? {}
        }
      });
    }

    return sessionStorageItem;
  }

  setItem(key: string, val: any): void {
    if (!environment.liveedit && this.compressPlanData && key === 'application') {
      this.setItem$.next({ key, val });
    } else {
      this.performSetItem(key, val);
    }
  }

  performSetItem(key: string, val: any): void {
    // Performance: tasks like setting items to session storage do not need to be executed prior application logic and page rendering.
    // As a result we should use setTimeout 0 to push these items to the end of the call stack to free up the DOM earlier.
    setTimeout(() => {
      const value = JSON.parse(val);
      if (key === 'application') {
        // move appConfig into memory
        const appConfig = _get(value, 'appConfig');
        this.appConfig = appConfig;
        if (!environment.liveedit) {
          delete value.appConfig;
        } else {
          delete value.appConfig.resourceBundle;
          delete value.appConfig.resourceMetadata;
        }

        if (this.compressPlanData) {
          // Use LZM compression on plans planTypeDataMap
          const plansPlanTypeDataMap = _get(value, 'plans.planTypeDataMap');
          this.compressPlanTypeDataMap(key, value, plansPlanTypeDataMap);
        }
      }

      sessionStorage.setItem(key, JSON.stringify(value));
    }, 0);
  }

  removeItem(key: string): void {
    // We aren't actually removing any top level sessionStorage items but if we did we
    // would delegate to the native API. If we find a use case for this we may want to
    // set appConfig back to defaults when application is removed but this situation
    // does not currently come up.
    sessionStorage.removeItem(key);
  }

  clear(): void {
    // We aren't actually clearing any top level sessionStorage items but if we did we
    // would delegate to the native API. If we find a use case for this we may want to
    // set appConfig back to defaults here but this situation does not currently come up.
    sessionStorage.clear();
  }

  key(val: number): string {
    return sessionStorage.key(val);
  }

  private getAppConfig() {
    return this.appConfig || APP_CONFIG_DEFAULTS;
  }

  private getStoredSession(key: string): StoredSession {
    const sessionStorageItem = sessionStorage.getItem(key);
    const sessionStorageObject = JSON.parse(sessionStorageItem);

    return { sessionStorageItem, sessionStorageObject };
  }

  private compressPlanTypeDataMap(key: string, value: any, planTypeDataMap: PlanTypeMetadataMap): void {
    if (!_isEqual(this.planTypeDataMap, planTypeDataMap)) {
      // keep a raw planTypeDataMap reference in memory for future comparisons
      this.planTypeDataMap = planTypeDataMap;
      this.compressAndSetPlanTypeDataMap(value.plans, planTypeDataMap);
    } else {
      const { sessionStorageObject } = this.getStoredSession(key);

      if (sessionStorageObject?.plans?.planTypeDataMap) {
        // we reuse the compressed planTypeDataMap when the new value and the previous one are equals
        value.plans.planTypeDataMap = sessionStorageObject.plans.planTypeDataMap;
      } else {
        // fallback: we compress again the planTypeDataMap when, for some reason, the previous compressed value wasn't stored
        this.compressAndSetPlanTypeDataMap(value.plans, planTypeDataMap);
      }
    }
  }

  private compressAndSetPlanTypeDataMap(state: PlansStateModel, planTypeDataMap: PlanTypeMetadataMap): void {
    const planTypeDataMapKeys = Object.keys(planTypeDataMap);
    planTypeDataMapKeys?.forEach(key => {
      const base64Payload = LZString.compressToUTF16(JSON.stringify(planTypeDataMap[key]));
      state.planTypeDataMap[key] = base64Payload;
    });
  }

  private initializeCompressionSettings(): void {
    const globalResourceBundle: any = config?.resourceBundle?.find(bundle => bundle.lang === null);
    const compressPlanData = globalResourceBundle?.resources?.find(resource => resource.name === 'Spa_Site_Compress_PlanData')?.value;
    if (compressPlanData !== undefined) {
      this.compressPlanData = !!compressPlanData;
    }
    const compressPlanDataDebounceTime = globalResourceBundle?.resources?.find(
      resource => resource.name === 'Spa_Site_Compress_PlanData_DebounceTime'
    )?.value;
    if (compressPlanDataDebounceTime !== undefined) {
      this.compressPlanDataDebounceTime = compressPlanDataDebounceTime;
    }
  }
}
