import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { AppConfigState, SetAppConfigLoaded, SpinnerEvent, SpinnerService } from 'vnext-shared';
import { LiveEditFacade } from './live-edit-facade.service';

@Component({
  selector: 'vnext-live-edit-root',
  templateUrl: './live-edit-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * The LiveEditRootComponent acts as the parent route for all of the dynamically generated components in live edit.
 * This loads our dynamic config in one of two ways.
 *
 * 1) If we directly attempt to hit a child route this will check if we have loaded our app config into session storage.
 *    If the config has not been loaded into session storage we will check for a valid JWT in localStorage and load it from platform.
 * 2) This will observe if our JWT has changed in localStorage. If so, then we will attempt to reload the config from platform as the
 *    user may no longer be allowed to access the config.
 *
 * Because parent routes are loaded before their children this will ensure all child routes will run this logic even if we directly
 * access them.
 */
export class LiveEditRootComponent implements OnInit {
  constructor(
    private readonly liveEditFacade: LiveEditFacade,
    private readonly store: Store,
    private readonly spinnerService: SpinnerService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (!this.store.selectSnapshot(AppConfigState.loaded)) {
      this.liveEditFacade.setLiveEditConfig$.subscribe({
        next: () => {
          //
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setTimeout(async () => {
            await this.router.navigateByUrl(this.router.url, { onSameUrlNavigation: 'reload', skipLocationChange: true }).then(() => {
              this.spinnerService.reset();
            });
          }, 0);
        }
      });
    } else {
      // if this component is being constructed and is already loaded we should set the loaded
      // flag to false so that if the user hard reloads the page we re-fetch the app config as
      // it is no longer being stored in session storage to prevent an overflow error
      this.spinnerService.show(SpinnerEvent.APP_CONFIG_LOADING);
      this.store.dispatch(new SetAppConfigLoaded(false));
    }

    this.store.select(AppConfigState.loading).subscribe({
      next: loading => {
        loading ? this.spinnerService.show(SpinnerEvent.APP_CONFIG_LOADING) : this.spinnerService.hide(SpinnerEvent.APP_CONFIG_LOADING);
      }
    });
  }
}
