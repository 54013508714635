import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';
import _isEqual from 'lodash.isequal';

@Injectable()
export class VnextRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return _isEqual(future.routeConfig, current.routeConfig) || !!future.data.reuseComponent;
  }
}
